import React from 'react';
import styled from 'styled-components';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { ContentWrapper } from '../common';
import { Title2 } from '../headings';
import ContactForm from './form';
import { RECAPTCHA_SITE_KEY } from '../../config';
import bgSrc from '../../images/bg-contact-m.jpg';
import logoSrc from '../../images/logo-rezidence-u-anicky.png';
import Footer from '../Footer';

const Wrapper = styled.div`
  position: relative;
  background-image: url(${bgSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  padding: 3.5rem 0 1rem 0;

  @media (min-width: 992px) {
    padding: 10rem 0 3rem;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 2.75rem;
  color: ${({theme}) => theme.colors.white};

  ${Title2} {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({theme}) => theme.colors.white};
    margin-bottom: 20px;

    @media(min-width: 768px) {
      margin-bottom: 40px;
      font-size: 2rem;
    }

    @media(min-width: 1200px) {
      font-size: 2.375rem;
    }
  }

  p {
    font-size: 1rem;
  }

  @media (min-width: 768px) {

    p {
      font-size: 1.125rem;
    }
  }

  @media (min-width: 992px) {
    p {
      font-size: 1.25rem;
      line-height: 2.25rem;
      max-width: 500px;
    }
  }

  @media (min-width: 1200px) {
    p {
      font-size: 1.5rem;
      line-height: 2.25rem;
      max-width: 500px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-weight: 700;
  font-size: 1rem;
  color: ${({theme}) => theme.colors.white};
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  a,
  a:hover {
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.875rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    /* gap: 150px; */
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 150px;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 220px;
  margin-bottom: 24px;
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

function Contact() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
      <Wrapper id="kontakt">
          <Content>
            <ContentWrapper>
              <Grid>
                <div>
                  <TitleWrapper>
                    <Title2>Hlásíme prodáno!</Title2>
                    <p>Všechny bytové jednotky už mají své nové majitele. Pokud byste si i&nbsp;tak přáli nás kontaktovat, využijte náš kontaktní formulář.</p>
                  </TitleWrapper>


                    <Info>
                      <span id="rua-phone">+420&nbsp;728&nbsp;573&nbsp;177</span>
                    </Info>
                    <Info>
                      <span id="rua-email">prodej@rezidenceuanicky.cz</span>
                    </Info>
                  </div>
                  <div>
                    <Logo src={logoSrc} alt="Logo - Rezidence u Aničky" />
                    <ContactForm />
                  </div>
              </Grid>
              <Footer />
            </ContentWrapper>
          </Content>
      </Wrapper>
    </GoogleReCaptchaProvider>
  );
}

export default Contact;