import React from 'react';
import styled from 'styled-components';
import { ContentWrapper } from "../common";
import { Title2 } from '../headings';
import { Section } from "../section";
import mapMarkerSvg from '../../images/svg/icon-map-marker.svg';
import keysSvg from '../../images/svg/icon-keys.svg';
import downloadSvg from '../../images/svg/icon-download.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import sampleSrc from '../../images/img-01.jpg';
import sample2Src from '../../images/img-02.jpg';
import sample3Src from '../../images/img-03.jpg';

const MapMarkerIcon = styled(mapMarkerSvg)`
  width: 100px;
  height: auto;
  fill: rgba(234, 234, 234, 0.8);
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
`;

const KeysIcon = styled(keysSvg)`
  width: 150px;
  height: auto;
  fill: rgba(234, 234, 234, 0.8);
  position: absolute;
  top: -10px;
  right: 20px;
  z-index: 0;

  @media (min-width: 768px) {
    top: -30px;
    right: 100px;
  }
`;

const DownloadIcon = styled(downloadSvg)`
  width: 18px;
  height: auto;

  path {
    fill: ${({theme}) => theme.colors.white} !important;
  }
`;

const AboutProjectSection = styled.div`
  position: relative;
`;

const LocationSecion = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3.5rem;

  ${Title2} {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 768px) {
    gap: 100px;
  }

  @media (min-width: 992px) {
    grid-template-columns:  1fr 1fr;
    gap: 2rem;
  }

  @media (min-width: 1200px) {
    /* grid-template-columns: 2fr 3fr; */
    gap: 3.5rem;
    grid-template-columns:  minmax(300px, 480px) 1fr;
  }
`;

const SecondGrid = styled(Grid)`
  grid-template-columns:  1fr;

  @media (min-width: 992px) {
    grid-template-columns:  1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns:  1fr minmax(300px, 480px);
  }
`;

const Text = styled.p`
  position: relative;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  z-index: 1;
  max-width: 480px;
`;

const StyledLink = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.black};
  padding: 0.575rem 2.25rem;
  transition: all .3s ease;
  border: 1px solid ${({theme}) => theme.colors.black};
  /* width: 100%; */

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.white};
    background-color:${({theme}) => theme.colors.secondary_grey};
    border: 1px solid ${({theme}) => theme.colors.secondary_grey};
  }

  /* @media (min-width: 576px) {
    width: auto;
  } */
`;

const SecondaryStyledLink = styled(StyledLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  background-color: ${({theme}) => theme.colors.secondary_grey};
  border: 1px solid ${({theme}) => theme.colors.secondary_grey};

  &:hover {
    background-color:${({theme}) => theme.colors.black};
    border: 1px solid ${({theme}) => theme.colors.black};
  }
`;

const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
`;

const DividerSection = styled.div`
  margin-top: 2.5rem;
  background-color: rgba(234,234,234,0.8);

  @media (min-width: 768px) {
    margin-top: 0;
    background: #ffffff;
    background: linear-gradient(90deg, rgba(234,234,234,0.8) 50%, rgba(255,255,255,1) 50%);
  }
`;

const Slogan = styled.p`
  padding: 32px 0 ;
  display: inline-block;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  background-color: rgba(234,234,234,0.8);


  @media (min-width: 768px) {
    padding: 32px 70px 32px 0;
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 900;
  }

  @media (min-width: 1400px) {
    font-size: 5rem;
    line-height: 6.5rem;
    font-weight: 900;
  }
`;

const ModelApartmentSection = styled.div`
  position: relative;

  ${StyledLink} {
    width: 220px;
  }
`;

const PhotoWrapper = styled.div`
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-width: 680px;
  }
`;

const PhotoText = styled.div`
  display: inline-block;
  padding: 15px;
  background-color: rgba(234, 234, 234, 0.8);

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  strong {
    font-weight: 700;
  }

  @media (min-width: 768px) {
    padding: 30px;
    width: 420px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(40px);

    p {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    position: relative;
    width: auto;
    transform: translateX(0);
  }

  @media (min-width: 1200px) {
    width: 420px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(40px);
  }
`;

const MapWrapper = styled.div`
  height: 300px;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  @media(min-width: 1400px) {
    width: 670px;
    height: 500px;
    background-color: ${({theme}) => theme.colors.bg_row_grey};
  }
`;

function AboutProject() {
  return (
    <>
    <Section id="o-projektu">
      <ContentWrapper>

        <Grid>
          <AboutProjectSection>
            <MapMarkerIcon />
            <Title2>
              O&nbsp;projektu
            </Title2>
            <Text>
              V&nbsp;krásné a&nbsp;klidné jihozápadní části historického města Rakovník, nedaleko prvorepublikové vily Anička, se nachází developerský projekt s&nbsp;příznačným názvem Rezidence U&nbsp;Aničky. Tento projekt je výjimečným spojením moderního bydlení s&nbsp;respektem k&nbsp;historickému dědictví tohoto královského města.
            </Text>
            <Text>
              Rezidence U&nbsp;Aničky je tvořena čtyřmi elegantními bytovými domy, které nabízí celkem 65&nbsp;bytových jednotek. Každý z&nbsp;těchto domů je navržen s&nbsp;důrazem na kvalitu provedení a&nbsp;komfort pro budoucí obyvatele. Architektonický styl projektu odráží prvorepublikovou éru, a&nbsp;to v&nbsp;kombinaci s&nbsp;moderními prvky, které zaručují pohodlí a&nbsp;funkčnost pro současný životní styl.
            </Text>
            <AnchorLink to="/#vyber-bytu">
              <StyledLink>
                výběr jednotek
              </StyledLink>
            </AnchorLink>
          </AboutProjectSection>
          <div>
            <PhotoWrapper>
              <img src={sampleSrc} alt="Foto" />
              <PhotoText>
                <p>
                  <strong>Vítejte v&nbsp;Rezidenci u&nbsp;Aničky.</strong><br />
                  Stačí se rozhodnout a&nbsp;doslova za pár dní začít žít ve fungl novém.
                </p>
              </PhotoText>
            </PhotoWrapper>
          </div>
        </Grid>

      </ContentWrapper>
    </Section>
      <DividerSection>
        <ContentWrapper>
          <Slogan>
          Dostupné bydlení<br />v&nbsp;Rakovníku
          </Slogan>
        </ContentWrapper>
      </DividerSection>
      <Section removeBtmPadding>
        <ContentWrapper>
          <SecondGrid>
          <div>
            <PhotoWrapper>
              <img src={sample2Src} alt="Foto" />
              <PhotoText>
                <p>
                  <strong>Poslední volné jednotky</strong><br />
                  Sousedy známe, ale přece se ještě na pár posledních čeká. Projekt nabízí poslední volné byty.
                </p>
              </PhotoText>
            </PhotoWrapper>
          </div>
            <ModelApartmentSection>
              <KeysIcon />
              <Title2>
                Přijďte na prohlídku
              </Title2>
              <Text>
               V&nbsp;rámci projektu jsou k&nbsp;dispozici poslední byty dispozic 2kk a&nbsp;3kk, které splní potřeby různých typů klientů. Každý byt je navržen s&nbsp;ohledem na detaily a&nbsp;využívá moderních technologií a&nbsp;materiálů, aby poskytoval maximální pohodlí a&nbsp;efektivitu.
              </Text>
              <BtnsWrapper>
                <AnchorLink to="/#kontakt">
                  <StyledLink>
                    chci přijít na prohlídku
                  </StyledLink>
                </AnchorLink>
                <SecondaryStyledLink as="a" href="/data/standardy.pdf" target="_blank" rel="noreferrer" title="Zobrazit standardy v PDF">
                  <span>standardy</span>
                  <DownloadIcon />
                </SecondaryStyledLink>
              </BtnsWrapper>
            </ModelApartmentSection>
          </SecondGrid>
        </ContentWrapper>
      </Section>
      <Section removeBtmPadding>

      <ContentWrapper>
        <Grid>
          <LocationSecion>
            <MapMarkerIcon />
            <Title2>Lokalita</Title2>
            <Text>
              "Tato lokalita nabízí dokonalou rovnováhu mezi městem a&nbsp;přírodou. Rakovník je vybaven nemocnicí, sportovními zařízeními a&nbsp;moderními nákupními centry. Zároveň vám otevírá brány do úchvatné Chráněné krajinné oblasti Křivoklátsko, která láká na nekonečné výlety do přírody.
            </Text>
            <Text>
              Díky výborné dopravní dostupnosti a&nbsp;blízkosti hlavního města Prahy je tato lokalita skutečně atraktivní. Stačí pouhá hodina jízdy po dálnici a&nbsp;jste v Praze. Stejně tak rychle se dostanete do Plzně. Vaše děti budou mít pohodlný přístup ke školám a&nbsp;sportovním zařízením v&nbsp;Rakovníku, ať už pěšky nebo pomocí městské hromadné dopravy."
            </Text>
          </LocationSecion>
          <div>
            <PhotoWrapper>
              <MapWrapper>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1076.1857787541585!2d13.723258046519605!3d50.09348403487402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDA1JzM2LjYiTiAxM8KwNDMnMjQuMyJF!5e0!3m2!1scs!2scz!4v1625201890692!5m2!1scs!2scz" title="an-map" allowFullScreen="" loading="lazy"></iframe>
              </MapWrapper>
              <PhotoText>
                <p>
                  <strong>Všude blízko.</strong><br />
                  Ať už půjdete doprovodit děti do školy nebo nakoupit potraviny, cesta vám zabere jen chvíli.
                </p>
              </PhotoText>
            </PhotoWrapper>
          </div>
        </Grid>
      </ContentWrapper>

    </Section>
    <Section>
        <ContentWrapper>
          <SecondGrid>
          <div>
            <PhotoWrapper>
              <img src={sample3Src} alt="Foto" />
            </PhotoWrapper>
          </div>
            <ModelApartmentSection>
              <Title2>
              Parkovací stání i&nbsp;sklep v&nbsp;ceně bytu
              </Title2>
              <Text>
                Bez starostí o&nbsp;parkování a&nbsp;s&nbsp;dostatkem úložného prostoru ve sklepě si budete moci užívat svůj nový domov naplno.
              </Text>
              <Text>
                Ke každému bytu v&nbsp;našem developerskému projektu získáte kompletní balíček výhod pro váš pohodlný život.
              </Text>
              <BtnsWrapper>
                <AnchorLink to="/#kontakt">
                  <StyledLink>
                    domluvit prohlídku
                  </StyledLink>
                </AnchorLink>
              </BtnsWrapper>
            </ModelApartmentSection>
          </SecondGrid>
        </ContentWrapper>
      </Section>
    </>
  );
}

export default AboutProject;