import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import data from '../../../data.json';
import { Section } from '../section';
import { ContentWrapper } from '../common';
import downloadSvg from '../../images/svg/icon-download.svg';
import searchSvg from '../../images/svg/icon-search.svg';
import mailSvg from '../../images/svg/icon-mail.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Title2 as DefaultTitle2 } from '../headings';

const DownloadIcon = styled(downloadSvg)`
  width: 18px;
  height: auto;
  fill: ${({theme}) => theme.colors.black};
  cursor: pointer;
`;

const SearchIcon = styled(searchSvg)`
  width: 17px;
  height: auto;
  fill: ${({theme}) => theme.colors.black};
  cursor: pointer;
`;

const MailIcon = styled(mailSvg)`
  width: 25px;
  height: auto;
  fill: ${({theme}) => theme.colors.black};
  cursor: pointer;
`;

const Title2 = styled(DefaultTitle2)`
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 16px;
  }

  @media (min-width: 1200px) {
    font-size: 3.375rem;
    font-weight: 900;
    margin-bottom: 0;
  }
`;

const DividerSection = styled.div`
  background: #ffffff;
  background-color: rgba(234,234,234,0.8);
  padding: 50px 0;
`;

const Spacer = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 100px;
  row-gap: 16px;

  @media (min-width: 1400px) {
    grid-template-columns: max-content 1fr;
  }
`;

const Th = styled.th``;
const CustomLink = styled(Link)``;

const Table = styled.table`
  width: 100%;
  color: ${({theme}) => theme.colors.black};

  .sm,
  .md,
  .lg,
  .xl {
    display: none;
  }

  .td-right {
    text-align: right;
  }

  ${Th} {
    /* color: ${({theme}) => theme.colors.secondary}; */
    font-size: 0.875rem;
    font-weight: 400;
    /* border-right: 1px solid ${({theme}) => theme.colors.secondary}; */
    padding: 10px 0.5rem;
    text-align: center;
    border-bottom: 1px solid #83929B;

    &.no-border {
      border-right: 0;
    }

    @media (min-width: 992px) {
      padding: 10px 1rem;
    }
  }

  tbody tr {
    border-bottom: 1px solid #83929B;
  }

  tbody tr:hover td {
    background-color: ${({theme}) => theme.colors.primary_grey};
  }

  tr td {
    padding: 10px 0.5rem;
    font-size: 0.875rem;
    text-align: center;

    @media (min-width: 992px) {
      padding: 10px 1rem;
    }
  }

  ${CustomLink} {
    display: inline-block;
    background-color: ${({theme}) => theme.colors.secondary};
    cursor: pointer;
    color: ${({theme}) => theme.colors.primary};
    padding: 0.25rem 0.5rem;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    transition: all 300ms ease;

    &:hover {
      color: ${({theme}) => theme.colors.white};
      border: 1px solid ${({theme}) => theme.colors.white};
      background-color: ${({theme}) => theme.colors.transparent};
      text-decoration: none;
    }
  }

  @media (min-width: 576px) {
    .sm {
      display: table-cell;
    }
  }

  @media (min-width: 768px) {
    .md {
      display: table-cell;
    }
  }

  @media (min-width: 992px) {
    .lg {
      display: table-cell;
    }
  }

  @media (min-width: 1200px) {
    .xl {
      display: table-cell;
    }
  }
`;

const Note = styled.span`
  display: block;
  font-size: 0.875rem;
  margin-top: 20px;
  padding-left: 10px;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 32px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
  align-items: center;

  @media (min-width: 768px) {
    display: grid;
    gap: 14px;
    grid-template-columns: 120px 80px 80px 80px 80px 80px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 180px 120px 120px 120px 120px 120px;
  }
`;

const FilterRowLabel = styled.span`
  display: block;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 700;

  @media (min-width: 768px) {
    display: inline-block;
    width: auto;
  }
`;

const CheckboxWrapper = styled.div`

  label {
    margin-bottom: 0;
  }
  /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 40px;
      /* margin-bottom: 12px; */
      cursor: pointer;
      font-size: 1rem;
      height: 20px;
      line-height: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @media (min-width: 768px) {
        font-size: 1.125rem;
      }

      @media (min-width: 992px) {
        font-size: 1.25rem;
        height: 40px;
        line-height: 40px;
        padding-left: 55px;
      }

    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;

      @media (min-width: 992px) {
        height: 40px;
        width: 40px;
      }
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #83929B;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 7px;
      top: 1px;
      width: 6px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

      @media (min-width: 992px) {
        left: 13px;
        top: 4px;
        width: 15px;
        height: 25px;
        border-width: 0 5px 5px 0;
      }
    }
`;

const EmptyResult = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  padding: 12px;
  margin-bottom: 0;
`;

const Info = styled(EmptyResult)`
  font-weight: 400;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin: 20px 0;
  }
`;

const getStatus = state => {
  if(state === 'available') {
    return <span>volný</span>;
  }
  if(state === 'reserved') {
    return <span>rezervace</span>;
  }
  if(state === 'sold') {
    return <span>prodáno</span>;
  }
  return '-'
}

function ApartmentSelection() {
  const [selectedTypes, setSelectedTypes] = useState(['1+kk', '2+kk', '3+kk', '4+kk']);
  const [selectedFloors, setSelectedFloors] = useState([1,2,3,4]);

  const handleAllChange = e => {
    const checked = e.target.checked;

    if(checked) {
      setSelectedTypes(['1+kk', '2+kk', '3+kk', '4+kk'])
    } else {
      setSelectedTypes([]);
    }
  }

  const handleTypeChange = e => {

    const values = [...selectedTypes];

    if(e.target.checked) {
      values.push(e.target.value);
    } else {
      values.splice(values.indexOf(e.target.value), 1);
    }

    setSelectedTypes(values);
  }

  const handleFloorChange = e => {

    const values = [...selectedFloors];

    if(e.target.checked) {
      values.push(Number(e.target.value));
    } else {
      values.splice(values.indexOf(Number(e.target.value)), 1);
    }

    setSelectedFloors(values);
  }

  // const getFilteredData = () => {
  //   if(selectedTypes.length === 4 && selectedFloors.length === 4) {
  //     return data;
  //   } else {
  //     return data.filter(item => selectedTypes.includes(item.type) &&  selectedFloors.includes(item.floor))
  //   }
  // }

  return (
    <>
      <DividerSection id="vyber-bytu">
        <ContentWrapper>
          <Grid>
            <Title2>Výběr bytu</Title2>
            <Spacer />
            <Spacer />
            <FilterSection>
              <FilterRow>
                <FilterRowLabel>
                  Dispozice
                </FilterRowLabel>
                <CheckboxWrapper>
                  <label className="container">vše
                    <input type="checkbox" checked={selectedTypes.length === 4} onChange={handleAllChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">1+kk
                    <input type="checkbox" value="1+kk" checked={selectedTypes.indexOf('1+kk') !== -1} onChange={handleTypeChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">2+kk
                    <input type="checkbox" value="2+kk" checked={selectedTypes.indexOf('2+kk') !== -1} onChange={handleTypeChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">3+kk
                    <input type="checkbox" value="3+kk" checked={selectedTypes.indexOf('3+kk') !== -1} onChange={handleTypeChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">4+kk
                    <input type="checkbox" value="4+kk" checked={selectedTypes.indexOf('4+kk') !== -1} onChange={handleTypeChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
              </FilterRow>
              <FilterRow>
                <FilterRowLabel>
                  Podlaží
                </FilterRowLabel>
                <CheckboxWrapper>
                  <label className="container">1.&nbsp;NP
                    <input type="checkbox" value="1" checked={selectedFloors.indexOf(1) !== -1} onChange={handleFloorChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">2.&nbsp;NP
                    <input type="checkbox" value="2" checked={selectedFloors.indexOf(2) !== -1} onChange={handleFloorChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">3.&nbsp;NP
                    <input type="checkbox" value="3" checked={selectedFloors.indexOf(3) !== -1} onChange={handleFloorChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <label className="container">4.&nbsp;NP
                    <input type="checkbox" value="4" checked={selectedFloors.indexOf(4) !== -1} onChange={handleFloorChange} />
                    <span className="checkmark"></span>
                  </label>
                </CheckboxWrapper>
              </FilterRow>
            </FilterSection>
          </Grid>
        </ContentWrapper>
      </DividerSection>
      <Section>
        <ContentWrapper>
        {/* <Table>
            <thead>
              <tr>
                <Th className="lg"></Th>
                <Th>číslo</Th>
                <Th className="sm">podlaží</Th>
                <Th className="md">dispozice</Th>
                <Th className="md" title="* dle vyhlášky č. 366/2013">plocha&nbsp;*</Th>
                <Th className="xl">balkon</Th>
                <Th className="xl">zahrádka</Th>
                <Th>stav</Th>
                <Th>cena v Kč</Th>
                <Th className="sm">karta bytu</Th>
                <Th>detail</Th>
                <Th className="lg">poptat</Th>
                <Th className="lg"></Th>
              </tr>
            </thead>
            <tbody>
              {
                getFilteredData().map(item => (
                  <tr key={`${item.building}_${item.id}`}>
                    <td className="lg"></td>
                    <td>{`B${item.building}_${item.id}`}</td>
                    <td className="sm">{`${item.floor}. NP`}</td>
                    <td className="md">{item.type}</td>
                    <td className="md">{item.sum_floor}&nbsp;m<sup>2</sup></td>
                    <td className="xl">{item.balcony ? <span>{item.balcony}&nbsp;m<sup>2</sup></span> : '-'}</td>
                    <td className="xl">{item.garden ? <span>{item.garden}&nbsp;m<sup>2</sup></span> : '-'}</td>
                    <td>
                      {getStatus(item.state)}
                    </td>
                    <td className="td-right">{item.price > 0 ? new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(Math.round(item.price)) : '-'}</td>
                    <td className="sm">
                      <a href={`/data/pdf/BD${item.building}_${item.id}.pdf`} target="_blank" rel="noreferrer">
                        <DownloadIcon />
                      </a>
                    </td>
                    <td>
                      <Link to={`/byty/bd${item.building}-b${item.id}`}>
                        <SearchIcon />
                      </Link>
                    </td>
                    <td className="lg">
                      <AnchorLink to="/#kontakt">
                        <MailIcon />
                      </AnchorLink>
                    </td>
                    <td className="lg"></td>
                  </tr>
                ))
              }
              {
                 getFilteredData().length === 0 && (
                  <tr>
                    <td colSpan="13">
                      <EmptyResult>
                        Žádný byt k zobrazení, upravte prosím filtr k zobrazení dostupných bytů.
                      </EmptyResult>
                    </td>
                  </tr>
                 )
              }
            </tbody>
          </Table>
          <Note>* dle vyhlášky č. 366/2013</Note> */}
          <Info>Všechny jednotky v&nbsp;developerském projektu jsou již vyprodány.</Info>
        </ContentWrapper>
      </Section>
    </>
  );
}

export default ApartmentSelection;