import React from 'react';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import Layout from '../components/layout';
import Seo from '../components/seo';
import { ContentWrapper } from '../components/common';
import AboutProject from '../components/AboutProject';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import ApartmentSelection from '../components/ApartmentSelection';
import iconLeftSvg from '../images/svg/icon-circle-arrow-left.svg';
import iconRightSvg from '../images/svg/icon-circle-arrow-right.svg';
import { Navigation, Pagination, EffectFade, Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

const IconLeft = styled(iconLeftSvg)`
  width: 40px;
  height: auto;
  cursor: pointer;
`;

const IconRight = styled(iconRightSvg)`
  width: 40px;
  height: auto;
  cursor: pointer;
`;

const SliderNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 32px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .swiper-pagination {
    position: relative;
    width: auto;
    top: 0;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 0.8;
    margin: 0 3px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #fff;
  }

  @media (min-width: 992px) {
    justify-content: flex-start;
    left: 60px;
    width: auto;
    transform: translateX(0);
  }
`;

const Panel = styled.div`
  position: relative;
  height: 530px;
  width: 100%;
  z-index: 1;
  margin-top: -100px;
  overflow: hidden;

  .swiper-container, .swiper {
    height: 530px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.30;
    z-index: 1;
  }

  .panel-image {
    position: absolute;
    width: 100%;
    height: 100% !important;
    z-index: 0;
  }

  @media (min-width: 1400px) {
    height: 800px;

    .swiper-container, .swiper {
      height: 800px;
    }
  }

  ${ContentWrapper} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 2;

  p {
    color: ${({theme}) => theme.colors.white};
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
    /* margin-top: 40px; */
    margin-bottom: 20px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    &.large {
      font-weight: 700;
    }

    @media (min-width: 768px) {
      /* margin-top: 80px; */
      font-size: 2rem;
      line-height: 2.5rem;

    }

    @media (min-width: 1400px) {
      font-size: 3.125rem;
      line-height: 4.5rem;
      margin-top: 60px;
      margin-bottom: 100px;

      &.large {
        margin-top: 0;
        margin-bottom: 60px;
      }
    }
  }
`;

const StyledLink = styled.span`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  text-decoration: none;
  cursor: pointer;
  color: ${({theme}) => theme.colors.white};
  background-color: transparent;
  padding: 0.575rem 2.25rem;
  transition: all .3s ease;
  border: 1px solid ${({theme}) => theme.colors.white};

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.white};
    background-color:${({theme}) => theme.colors.secondary_grey};
    border: 1px solid ${({theme}) => theme.colors.secondary_grey};
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

function Index () {

  return (
    <Layout showPageTitle>
      <Seo />
      <Panel>
        <Swiper
        modules={[Navigation, EffectFade, Pagination, Autoplay]}
          effect="fade"
          loop
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
          }}
          slidesPerView={1}
          speed={1000}
          autoplay={{
            delay: 5000
            }
          }
        >
        <SwiperSlide>
          <StaticImage src="../images/bg-panel-01.jpg" className="panel-image" placeholder="blurred" alt="Photo" />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage src="../images/bg-panel-02.jpg" className="panel-image" placeholder="blurred" alt="Photo" />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage src="../images/bg-panel-03.jpg" className="panel-image" placeholder="blurred" alt="Photo" />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage src="../images/bg-panel-04.jpg" className="panel-image" placeholder="blurred" alt="Photo" />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage src="../images/bg-panel-05.jpg" className="panel-image" placeholder="blurred" alt="Photo" />
        </SwiperSlide>

      </Swiper>

        <SliderNavigation>
          <IconLeft className="swiper-button-prev" style={{position: 'relative', margin: 0}} />
          {/* </div> */}
          <div className="swiper-pagination"></div>
          <IconRight className="swiper-button-next" style={{position: 'relative', margin: 0,}} />
        </SliderNavigation>
        <Content>
          <ContentWrapper>
            <p>Všechny byty v&nbsp;Rezidenci u&nbsp;Aničky jsou již vyprodány</p>
            <p className="large">Děkujeme!</p>
            <BtnWrapper>
              <AnchorLink to="/#vyber-bytu">
                <StyledLink>
                  vybrat byt
                </StyledLink>
              </AnchorLink>
            </BtnWrapper>
          </ContentWrapper>
        </Content>
      </Panel>
      <AboutProject />
      <ApartmentSelection />
    </Layout>
  )
}

export default Index;
