import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setIn } from 'final-form';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import { globalHistory } from "@reach/router";
import { MESSAGE_URL, } from '../../config';
// import data from '../../../data.json';

const validationSchema = yup.object({
  name: yup.string().nullable(true).required('Pole je povinné'),
	email: yup.string().nullable(true).required('Pole je povinné').email('Email musí být ve správném tvaru'),
  phone: yup.string().nullable(true).required('Pole je povinné'),
  // interest: yup.string().nullable(true).required('Pole je povinné'),
});

const validateFormValues = (schema) => async (values) => {
	if (typeof schema === 'function') {
		schema = schema();
	}
	try {
		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
		}, {});

		return errors;
	}
};

const validate = validateFormValues(validationSchema);

const Input = styled.input`
  height: 50px;
  padding: 0 0.75rem;
  border: 0;
  width: 100%;

  border: 2px solid ${({theme, hasError}) => hasError ? theme.colors.error : theme.colors.white};

  &:focus {
    outline: 0;
    padding: 0 0.75rem;
  }

  @media(min-width: 992px) {
    height: 60px;
    padding: 0 0.75rem;
  }
`;

const Select = styled.select`
  height: 50px;
  padding: 0 0.75rem;
  border: 0;
  width: 100%;

  border: 2px solid ${({theme, hasError}) => hasError ? theme.colors.error : theme.colors.white};

  &:focus {
    outline: 0;
    padding: 0 0.75rem;
  }

  @media(min-width: 992px) {
    height: 60px;
    padding: 0 0.75rem;
  }
`;

const InputWrapper = styled.div`
`;

const ErrorMessage = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.error};
  padding-left: 0.75rem;
  margin-bottom: 0;
`;

const SubmitError = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.error};
  margin-bottom: 0;
  grid-column: span 2;
`;

const ButtonWrapper = styled.div`
  padding-top: 2rem;
  text-align: center;

  button {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: none;
    font-weight: 700;
    border: 0;
    background-color: ${({theme}) => theme.colors.secondary};
    color: ${({theme}) => theme.colors.primary};
    padding: 0.75rem;
    width: 100%;

    &:disabled {
      background-color: ${({theme}) => theme.colors.btn_disabled};
      color: ${({theme}) => theme.colors.white};
      cursor: not-allowed;
    }

    &:focus {
      border: 0;
      outline: 0;
    }
  }

  @media(min-width: 576px) {
    text-align: right;

    button {
      width: auto;
      min-width: 180px;
    }
  }

  @media (min-width: 1400px) {
    grid-column: span 2;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.75rem;
  row-gap: 0.75rem;
  padding-top: 2.5rem;

  @media (min-width: 768px) {
    padding-top: 0;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

`;

const getApartmentId = pathname => {

  if(pathname && pathname.startsWith('/byty/bd')) {
    const urlData = pathname.replace('/byty/', '');
    const infoData = urlData.split('/');
    const houseInfoData = infoData[0].split('-');
    return `Dům: ${houseInfoData[0].toUpperCase()}, byt: ${houseInfoData[1].toUpperCase()}`
  }
  return null;
}

function ContactForm() {
  const [showError, setShowError] = useState(false);
  const [isSent, setSent] = useState(false);
  const { executeRecaptcha} = useGoogleReCaptcha();

  const pathname = globalHistory.location.pathname;

  const onSubmit = async (values, form) => {
    const token = await executeRecaptcha("contact_form");

    setShowError(false);
    setSent(false);
    axios.post(`${MESSAGE_URL}/message.php`, {
      ...values,
      token,
      apartmentId: getApartmentId(pathname),
      url: globalHistory.location.href,

    })
    .then(response => {
      setShowError(false);
      setSent(true);
      form.restart();
      window.dataLayer.push({'event': `formSubmit-contact`});
    })
    .catch(error => {
      setShowError(true);
      setSent(false);
    })
  }

  return (
    <Grid>
       <Form
      onSubmit={(values, form) => onSubmit(values, form)}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        interest: '',
      }}
      validate={validate}
      render={({ handleSubmit, hasValidationErrors, submitting}) => (
        <>
         <Field
            name="name"
            type="text"
          >
            {({ input, meta }) => (
              <InputWrapper>
                <Input {...input} hasError={meta.error && meta.touched} placeholder="Jméno a příjmení *" />
                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
              </InputWrapper>
            )}
          </Field>
          <Field
            name="interest"
          >
            {({ input, meta }) => (
              <InputWrapper>
                <Select {...input} hasError={meta.error && meta.touched} defaultValue="" placeholder="Mám zájem o *">
                  <option value="" disabled>Mám zájem o</option>
                  <option value="obecné informace">obecné informace</option>
                  {/* {
                    data.map(item => (
                      <option key={`Byt B${item.building}_${item.id}`} value={`Byt B${item.building}_${item.id}`}>{`Byt B${item.building}_${item.id}`}</option>
                    ))
                  } */}
                </Select>
                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
              </InputWrapper>
            )}
          </Field>
          <Field
            name="phone"
            type="text"
          >
            {({ input, meta }) => (
              <InputWrapper>
                <Input {...input} hasError={meta.error && meta.touched} placeholder="Telefon *" />
                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
              </InputWrapper>
            )}
          </Field>
        <Field
          name="email"
          type="text"
        >
          {({ input, meta }) => (
            <InputWrapper>
              <Input {...input} hasError={meta.error && meta.touched} placeholder="Email *" />
              {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
            </InputWrapper>
          )}
        </Field>
      { showError && <SubmitError>Při odesílání zprávy došlo k&nbsp;chybě!</SubmitError> }
      <ButtonWrapper>
        <button onClick={handleSubmit} disabled={hasValidationErrors || submitting || isSent}>{isSent ? 'Odesláno' : 'Odeslat'}</button>
      </ButtonWrapper>
      </>
       )}
       />
    </Grid>
  );
}

export default ContactForm;