import React from 'react';
import styled from 'styled-components';
import { Link, } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import logoSrc from '../../images/logo-rezidence-u-anicky.png';
import { HOME, } from '../../routes';
import { globalHistory } from "@reach/router";

const LogoWrapper = styled(Link)``;

const Wrapper = styled.div`
  padding-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: transparent;

  h1 {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 180px;
  }

  @media (min-width: 768px) {
    img {
      max-width: 225px;
    }

  }

  @media (min-width: 992px) {
    margin-left: 60px;
    margin-right: 60px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const NavWrapper = styled.div`
  font-size: 0.875rem;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: 12px;
    font-weight: 600;
  }

  ul li {
    display: inline;
    text-transform: uppercase;
    transition: opacity 0.5s;
  }

  ul li a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.white};
  }

  ul li:hover a,
  ul li a.active {
    color: ${({theme}) => theme.colors.secondary};
  }

  @media (min-width: 576px) {
    font-size: 1.125rem;

    ul {
      font-weight: 700;
      gap: 15px;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.375rem;
  }

  @media (min-width: 992px) {
    ul li {
      margin-left: 40px;
    }
  }

  @media (min-width: 1200px) {
    font-size: 1.875rem;

    ul {
      font-weight: 700;
      gap: 15px;
    }

    ul li {
      margin-left: 40px;
    }
  }
`;

function Navigation({showPageTitle}) {

  return (
    <Wrapper>
      <LogoWrapper to={HOME}>
        { showPageTitle && <h1>Rezidence u&nbsp;Aničky</h1> }
        <img src={logoSrc} alt="Logo - Rezidence u Aničky" />
      </LogoWrapper>
      <NavWrapper>
        <ul>
          <li>
            <AnchorLink to="/#o-projektu" title="O&nbsp;projektu" />
          </li>
          <li>
            <AnchorLink
              className={ globalHistory.location.pathname.startsWith('/byty/') ? 'active' : '' }
              to="/#vyber-bytu"
              title="Výběr bytu" />
          </li>
          <li>
            <AnchorLink to="/#kontakt" title="Kontakt" />
          </li>
        </ul>
      </NavWrapper>
    </Wrapper>
  )
}

export default Navigation;