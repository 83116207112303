import React from 'react';
import { bool } from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import Modal from 'react-modal';
import styled, {ThemeProvider} from 'styled-components';
import Header from './Header';
import theme from '../theme';
import Contact from './Contact';

const PageWrapper = styled.div`
  max-width: 1920px;
  background-color: ${({theme}) => theme.colors.white};
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

Modal.setAppElement('#___gatsby');

const Layout = ({ isSubpage, showPageTitle, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        {/* <InfoModal /> */}
        <PageWrapper>
          <Header isSubpage={isSubpage} showPageTitle={showPageTitle} />
          <Content>
            <main>{children}</main>
          </Content>
          <Contact />
        </PageWrapper>
      </CookiesProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  isSubpage: bool,
  showPageTitle: bool,
}

Layout.defaultProps = {
  isSubpage: false,
  showPageTitle: false,
}

export default Layout;
