import React from 'react';
import styled from 'styled-components';

const CustomLink = styled.span`
`;

const FooterWrapper = styled.footer`
  margin-top: 50px;
  color: ${({theme}) => theme.colors.white};
  opacity: 0.8;

  a, ${CustomLink} {
    color: ${({theme}) => theme.colors.white};
    text-decoration: underline;
    cursor: pointer;
  }

  a:hover, ${CustomLink}:hover {
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 992px) {
    margin-top: 100px;
    p {
      margin-bottom: 0;
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
  }
`;


function Footer() {

  return (
    <FooterWrapper>
      <Grid>
        <p><a href="/data/ochrana-osobnich-udaju.pdf" target="_blank" rel="noreferrer" title="Zobrazit v PDF">Ochrana osobních údajů</a> | <CustomLink id="c-settings" aria-haspopup="dialog">Nastavení cookies</CustomLink></p>
        <p>&copy; 2023 Všechna práva vyhrazena rezidenceuanicky.cz</p>
      </Grid>
  </FooterWrapper>
  );
}

export default Footer;