import styled from 'styled-components';

export const Section = styled.div`
  background-color: ${({theme, bgColor}) => bgColor ? `${bgColor}` : theme.colors.white};
  padding: 3.5rem 0 1rem 0;

  @media(min-width: 768px) {
    padding: 6.25rem 0;

    ${({removeBtmPadding}) => removeBtmPadding && `
      padding-bottom: 0;
    `}
  }

  ${({removeBtmPadding}) => removeBtmPadding && `
    padding-bottom: 0;
  `}
`;

export const PaddedSection = styled.div`
  padding: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  @media(min-width: 992px) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
`;